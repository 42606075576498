@import url('https://fonts.googleapis.com/css?family=Space+Mono');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  font-family: 'Space Mono', monospace, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.mainTimer {
  width: 400px;
  height: 50px;
  text-align: center;
  background: rgba(0,0,0,.45);
  border-radius: 50%;
  box-shadow: 0 0 25px 30px rgba(0,0,0,.45);
  color: #fff;
}
